<template>
    <div>
        <v-container fill-height style="height: 99vh;" class="ma-0 pa-0" fluid>
            <v-layout row wrap justify-space-around justify-center align-center mx-2>
                <v-flex xs12 sm8 md6 lg3 xl3>
                    <form @submit.prevent="login" autocomplete="off">
                        <v-card  color="" outlined class="elevation-0 rounded-lg ma-2">
                            <v-card-text>
                                <v-img :src="logo"  class="mx-auto mt-5 rounded-xl" width="200"></v-img>
                            </v-card-text>
                            <v-card-text>
                                <v-text-field class="mx-1" rounded v-model="auth.admin_name" outlined label="Username">
                                </v-text-field>
                                <v-text-field class="mx-1" rounded v-model="auth.admin_password" outlined
                                    label="Password" type="password"></v-text-field>
                                <div class="mx-1">
                                    <v-btn block class="elevation-0" x-large rounded color="primary" type="submit" >Login</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </form>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                auth: {
                    admin_name: '',
                    admin_password: ''
                },
                logo: require('../assets/logo.svg')
            }
        },
        methods: {
            login() {
                axios.post('auth.php', this.auth, {
                        headers: {
                            'Authorization': 'skip'
                        }
                    })
                    .then(r => {
                        if (r.data.isAuth) {
                            let user = r.data.user
                            this.$store.state.auth = {
                                user: user,
                                isAuth: r.data.isAuth
                            }
                            localStorage.setItem('token', r.data.token)
                            localStorage.setItem('user', JSON.stringify(r.data.user))
                            this.$router.push({
                                path: '/home'
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },

    }
</script>